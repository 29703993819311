import React from 'react'

import * as Styled from './Styled'
import * as Links from '../Links'

/**
 */
export const FirstSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
    StrongText = Styled.StrongText,
  } = {},
}) => (
  <Container>
    <Title>プロジェクト管理</Title>
    <Description>
      プロジェクトを作成し、プロジェクトごとに建材を追加したり、管理することができます。
      <StrongText>タグ機能</StrongText>
      により、ユーザー固有の情報を建材に付与して建材管理も可能!CSVでインポート、エクスポートにも対応しているのでバックアップや他のサービスとの連携など利用方法は自由自在です。
    </Description>
  </Container>
)

/**
 */
export const SecondSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>通知機能</Title>
    <Description>
      プロジェクトに製品を追加していると、廃番になった製品や情報が変更になった製品の通知が届きます。知らぬ間に製品が廃番になっていて気づかなかった...ということが無くなります。廃番になった製品にはサムネイルに廃番表示がつくので一目瞭然です。
    </Description>
  </Container>
)

/**
 */
export const ThirdSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>サンプル/資料請求</Title>
    <Description>
      非常に面倒だったサンプル請求作業が劇的に改善。複数のメーカーへの複数のサンプル請求もワンクリックです。サンプルも送付先も複数の場所を設定し、一度に送付依頼可能。請求履歴もあるので送付/未送付が一目瞭然です。
    </Description>
  </Container>
)

/**
 */
export const FourthSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>マテリアルボード</Title>
    <Description>
      マテリアルボードもドラッグ&ドロップで簡単に作成できます。画像だけでは無く、建材情報も紐付いているのでマテリアルボード上から製品情報を見ることも可能。PDFへ書き出したり、好きな画像/図面を読み込んだり、色やフォントを調整したり、デジタルですべて完結。
    </Description>
  </Container>
)

/**
 */
export const FifthSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>コラボレーション</Title>
    <Description>
      プロジェクトや、プロジェクトに追加された製品、マテリアルボードは他のArch-LOGユーザーへ対して閲覧、編集権限等、権限別に共有が可能です。複数の人間でプロジェクトを共有してプロジェクトを進める事ができます。
      Arch-LOG Cloud
      Renderingはレンダリングを他の人へリアルタイムに共有することもできます。
    </Description>
  </Container>
)

/**
 */
export const SixSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
    StrongText = Styled.StrongText,
  } = {},
}) => (
  <Container>
    <Title>BIMダウンロード</Title>
    <Description>
      BIMソフトウェアのユーザーであれば、Arch-LOGに登録されている製品のBIMダウンロード※が可能です。
      <StrongText>プラグイン</StrongText>
      を利用することで、直接BIMソフトウェアへBIMデータを読み込み、利用可能です。常に最新の建材情報をダウンロードの都度読み込むため、データが古くなって使えないということが無くなります。※BIMアイコンがついている製品のみ。一部の製品はBIMソフトウェアへの直接読み込みは対応していません。
    </Description>
  </Container>
)

/**
 */
export const SevenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>BIMリクエスト</Title>
    <Description>
      建材には無数の数があり、すべての建材がBIM化されるまではまだまだ長い道のりが必要です。BIMアイコンがついていない、BIM化されていない建材はBIMリクエストを送ることが可能です。プロジェクトに追加されている製品の中から、BIMリクエストを送って下さい。あなたの1票で、すべての建材のBIM化が実現されるかもしれません!?※急務のプロジェクト対応については
      <Links.ContactUs subject="※急務のプロジェクト対応について">
        こちら
      </Links.ContactUs>
      から お問い合わせ下さい。
    </Description>
  </Container>
)

/**
 */
export const EightSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>集客に対応する共有パラメータ</Title>
    <Description>
      RUG(Revit User
      Group)のパラメータも内包するArch-LOGのパラメータ仕様は、Arch-LOG内で統一されているため、ユーザーはBIMダウンロードした後に、そのままBIMソフトウェア上で集計に利用することが可能です。もう、パラメータの問題で悩まされることはありません。
    </Description>
  </Container>
)

/**
 */
export const NineSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>リアルタイムレンダリング</Title>
    <Description>
      Arch-LOGのレンダリングプラグインによって提供されるリアルタイムレンダリング機能(Arch-LOG
      Cloud
      Rendering)は世界最高峰の物理的なレンダリングエンジンIrayを利用したレンダリングを高性能なクラウドサーバーにて無料で提供します。ユーザーはローカルPCの性能に関わらず、高精度な写真画質のレンダリングをリアルタイムに行う事ができます。※無料のレンダリングは解像度は1920×1080まで。レンダリングは続けて1時間まで。
    </Description>
  </Container>
)

/**
 */
export const TenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>軽量なBIMデータ</Title>
    <Description>
      BIMデータは3次元データかつ、様々な付加情報を持つためプロジェクト全体で考えるとデータサイズが殆どの場合肥大化します。Arch-LOGではクラウドでのフォトリアルレンダリング機能が付属しているため、BIMデータ内で重いデータを保持する必要がありません。設計に必要十分なデータサイズのBIMデータをBIMデータ上で保持し軽量なBIMデータの恩恵を受けながら同時に、レンダリング用の高精細なデータはクラウドサーバー上に用意されていることによって、ユーザーはレンダリング時には高精細なレンダリング結果を得る事ができます。
    </Description>
  </Container>
)

/**
 */
export const ElevenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>LODに対応した図面表現</Title>
    <Description>
      Arch-LOGに登録されているBIMデータはLODに対応した図面を保持しています。ユーザーは統一されてないBIMデータ基準に頭を悩まされることは有りません。
    </Description>
  </Container>
)

/**
 */
export const TwelveSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>誰でもCGエキスパート</Title>
    <Description>
      一般的にリアルなCGレンダリングというのは高性能なレンダリングエンジンだけで実現できるわけではありません。物理的なマテリアルの設定も重要です。Arch-LOGに登録されているBIMデータはすべて対となって高精細な表現を行うための物理的なマテリアルデータを含むデータをクラウドに保持しているため、Arch-LOGからダウンロードしたBIMデータでBIMデータを構築するとそれだけでリアルなCGのための設定を行なっていることになります。すなわち、ユーザーはレンダリングボタンをワンクリックするだけでCGエキスパートが作るようなCGを作成することができます。
    </Description>
  </Container>
)

/**
 */
export const ThirteenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>高度なレンダリング設定</Title>
    <Description>
      基本的にワンクリックでもリアルにレンダリング可能なArch-Log Cloud Renderingですが、高度な設定も可能です。露出、ホワイトバランス、絞り値(ぼかし)、解像度/画像比率設定、自動保存、HDRIの設定、環境光設定など様々な高度な設定も可能です。各BIMソフトウェアのプロジェクトの緯度軽度設定、太陽の位置設定と連動しているので、BIMソフトウェア側で太陽の位置が移動すると、レンダリング上の太陽の位置も連動して変化します。
    </Description>
  </Container>
)

/**
 */
export const FourteenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>輝度/照度シミュレーション</Title>
    <Description>
      Arch-LOGに登録されている照明はIESデータを保持し、物理的に正確な輝度/照度シミュレーションと、フォトリアルレンダリングを両立するように特別に設定されています。したがって、Arch-LOG
      Cloud
      Renderingにおいて、ワンクリックで空間の輝度/照度を把握、検証することが可能です。
    </Description>
  </Container>
)

/**
 */
export const FifteenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>360度VR画像出力</Title>
    <Description>
      Arch-LOG Cloud
      RenderingはVRにも対応します。360度VR用の画像出力が簡単にできるので、VRヘッドセットを利用したプレゼンテーション等も可能です。Arch-LOG
      Cloud
      Renderingでのレンダリングした画像をVRヘッドセットで体験すると計画中の建物がその場に完成したような表現が可能です。
    </Description>
  </Container>
)

/**
 */
export const SixteenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>
      プレゼンテーション高解像度
      <br />
      レンダリングサービス(占有予約システム)
    </Title>
    <Description>
      1920✖1080の解像度を超えるレンダリングを行いたいときは、有料で特別に用意されたクラウドの高性能レンダリングリソースを利用できる占有予約システムが利用可能です。ユーザーは予めクラウドリソースの予約を行うことでArch-LOG
      Cloud
      Renderingを利用時に自動的に占有のクラウドリソースを占有して利用することができるようになります。※占有予約システムに関しては
      <Links.OnDemand>こちら</Links.OnDemand>
    </Description>
  </Container>
)

/**
 */
export const SeventeenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>プライベートなBIMライブラリ</Title>
    <Description>
      社内でのBIMライブラリ管理に問題を抱えていませんか？Arch-LOGでは、一般に公開されない自社ライブラリをArch-LOGに登録(※有料サービス)することもできます。自社のライブラリをArch-LOGと同じUI、ワークフローで一元管理することが可能です。
    </Description>
  </Container>
)

/**
 */
export const EighteenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>エンタープライズ向けドメイン管理機能</Title>
    <Description>
      企業でユーザーアカウントの作成/無効化を管理されたい場合は、同一ドメインのアカウントを管理できるドメイン管理機能が提供可能です。ドメイン管理者は、同一ドメインのアカウントの管理者権限追加/無効化、通常アカウントの作成/無効化、リスト出力等を行うことができます。
    </Description>
  </Container>
)
