import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const SectionContainer = ({ children, ...props }) => {
  return (
    <div className={styles.SectionContainer} {...props}>
      {children}
    </div>
  )
}
