import React from 'react'

import { Content as MeritSectionContent } from './MeritSection'
import { Content as FunctionSectionContent } from './FunctionSection'

import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
const BackgroundImage = () => <div className={styles.BackgroundImage}></div>

/**
 */
const TextArea = ({ children }) => (
  <div className={styles.TextArea}>{children}</div>
)

/**
 */
const Headline = () => (
  <h2 className={styles.Headline}>
    <Literals.Headline />
  </h2>
)

/**
 */
const Description = () => (
  <p className={styles.Description}>
    <Literals.Description />
  </p>
)

/**
 */
const Header = () => (
  <div className={styles.Header}>
    <BackgroundImage />
    <TextArea>
      <Headline />
      <Description />
    </TextArea>
  </div>
)

/**
 */
const Body = () => (
  <div className={styles.Body}>
    <MeritSectionContent />
    <FunctionSectionContent />
  </div>
)

/**
 */
export const Content = ({ children }) => (
  <Container>
    <Header />
    <Body />
  </Container>
)
