import React from 'react'

import * as Images from 'components/Images/Merit'

import * as styles from './styles.module.scss'

/**
 */
export const FirstSection = () => {
  return (
    <Images.FirstSection
      className={styles.Image}
      alt="Directly search and compare building materials"
    />
  )
}

/**
 */
export const SecondSection = () => {
  return (
    <Images.SecondSection
      className={styles.Image}
      alt="Free to use and keeps evolving"
    />
  )
}

/**
 */
export const ThirdSection = () => {
  return (
    <Images.ThirdSection
      className={styles.Image}
      alt="Project-based building material management and FM"
    />
  )
}

/**
 */
export const FourthSection = () => {
  return (
    <Images.FourthSection
      className={styles.Image}
      alt="Business efficiency ・ DX is all Arch-LOG"
    />
  )
}

/**
 */
export const FifthSection = () => {
  return (
    <Images.FifthSection
      className={styles.Image}
      alt="You can share it with everyone"
    />
  )
}

/**
 */
export const SixSection = () => {
  return <Images.SixSection className={styles.Image} alt="BIM correspondence" />
}
