import React from 'react'

import * as Styled from './Styled'
import * as Links from '../Links'

/**
 */
export const FirstSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
    StrongText = Styled.StrongText,
  } = {},
}) => (
  <Container>
    <Title>project management</Title>
    <Description>
      You can create projects and add or manage building materials for each
      project. I can do it.
      <StrongText>With the tag function</StrongText>, user-specific information
      can be added to building materials to manage building materials! It also
      supports import and export with CSV, so it can be used for backup and
      other services. You can use it freely, such as in cooperation.
    </Description>
  </Container>
)

/**
 */
export const SecondSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Notification function</Title>
    <Description>
      Discontinued products and information changed while adding products to the
      project You will receive a product notification. Before I knew it, the
      product was out of print and I didn't notice it ... That will disappear.
      Discontinued products will have a discontinued display on the thumbnail.
      It's obvious at a glance.
    </Description>
  </Container>
)

/**
 */
export const ThirdSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Sample/Document request</Title>
    <Description>
      Dramatically improved sample requesting work, which was very troublesome.
      Multiple samples to multiple manufacturers Billing is also one click. You
      can set multiple locations for both samples and destinations and request
      sending at once. Since there is also a billing history, it is obvious
      whether it has been sent or not.
    </Description>
  </Container>
)

/**
 */
export const FourthSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Material board</Title>
    <Description>
      You can easily create a material board by dragging and dropping. Not just
      images Since building material information is also linked, it is possible
      to see product information from the material board. Export to PDF, import
      your favorite images / drawings, adjust colors and fonts, It's all
      digital.
    </Description>
  </Container>
)

/**
 */
export const FifthSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>collaboration</Title>
    <Description>
      Browsing projects, products added to projects, and material boards to
      other Arch-LOG users, It is possible to share by authority such as editing
      authority. Sharing a project with multiple people and proceeding with the
      project I can do it. Arch-LOG Cloud Rendering can also share renderings to
      others in real time.
    </Description>
  </Container>
)

/**
 */
export const SixSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
    StrongText = Styled.StrongText,
  } = {},
}) => (
  <Container>
    <Title>BIM download</Title>
    <Description>
      If you are a BIM software user, you can download BIM * of products
      registered in Arch-LOG.<StrongText>By using the plug-in</StrongText> , BIM
      data can be read and used directly into the BIM software. Always up to
      date Since the building material information is read each time it is
      downloaded, the data will not be out of date and unusable. * Only products
      with the BIM icon. Some products do not support direct loading into BIM
      software.
    </Description>
  </Container>
)

/**
 */
export const SevenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>BIM request</Title>
    <Description>
      There are innumerable building materials, and there is still a long way to
      go before all building materials are converted to BIM. Building materials
      that do not have a BIM icon and are not BIM can be sent a BIM request.
      Please send a BIM request from the products added to the project. With
      your one vote BIM conversion of all building materials may be realized !?
      * Click
      <Links.ContactUs subject="※About urgent project correspondence">
        here
      </Links.ContactUs>
      for urgent project support Please contact us.
    </Description>
  </Container>
)

/**
 */
export const EightSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Shared parameters for attracting customers</Title>
    <Description>
      Arch-LOG parameter specifications that include RUG (Revit User Group)
      parameters are unified within Arch-LOG. After downloading BIM, users can
      use it for aggregation on BIM software as it is. It is possible. No more
      worrying about parameter issues.
    </Description>
  </Container>
)

/**
 */
export const NineSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Photo real-time rendering</Title>
    <Description>
      Photo real-time rendering capabilities provided by Arch-LOG's rendering
      plugin (Arch-LOG Cloud Rendering) is rendering using the world's best
      physical rendering engine Iray Is provided free of charge on a
      high-performance cloud server. Users are highly accurate regardless of the
      performance of the local PC You can render photo quality in real time. *
      Free rendering has a resolution of up to 1920 x 1080. Rendering continues
      for up to 1 hour.
    </Description>
  </Container>
)

/**
 */
export const TenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Lightweight BIM data</Title>
    <Description>
      BIM data is 3D data and has various additional information, so the data
      size is large when considering the entire project. In most cases it
      becomes bloated. Arch-LOG comes with a photorealistic rendering feature in
      the cloud, so No need to keep heavy data in BIM data. BIM data with
      sufficient data size for design High-definition data for rendering while
      retaining on BIM data and benefiting from lightweight BIM data By being
      prepared on the cloud server, users can render with high definition when
      rendering. You can get the result.
    </Description>
  </Container>
)

/**
 */
export const ElevenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Drawing representation corresponding to LOD</Title>
    <Description>
      BIM data registered in Arch-LOG holds drawings corresponding to LOD. Users
      don't have to worry about non-uniform BIM data standards.
    </Description>
  </Container>
)

/**
 */
export const TwelveSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Anyone CG expert</Title>
    <Description>
      In general, realistic CG rendering is only a high-performance rendering
      engine. It cannot be achieved. The physical material settings are also
      important. On Arch-LOG All registered BIM data are paired and physical
      material data for high-definition expression. Since the data including is
      held in the cloud, the BIM data downloaded from Arch-LOG is used. When you
      build BIM data, you are setting for realistic CG by itself. That is, the
      user can make it with a single click of the render button by a CG expert.
      You can create CG.
    </Description>
  </Container>
)

/**
 */
export const ThirteenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Advanced rendering settings</Title>
    <Description>
      Arch-Log Cloud Rendering that can be rendered realistically with just one
      click, Advanced settings are also possible. Exposure, white balance,
      aperture value (blurring), resolution / image ratio setting, automatic
      saving, Various advanced settings such as HDRI settings and ambient light
      settings are also possible. Of each BIM software project Since it is
      linked with the latitude mild setting and the sun position setting, when
      the sun position moves on the BIM software side, The position of the sun
      on the rendering also changes accordingly.
    </Description>
  </Container>
)

/**
 */
export const FourteenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Brightness/illuminance simulation</Title>
    <Description>
      The lighting registered in Arch-LOG retains IES data, and physically
      accurate brightness / illuminance simulation, It is specially set to
      balance photoreal rendering. Therefore, Arch-LOG Cloud With Rendering, it
      is possible to grasp and verify the brightness / illuminance of the space
      with one click.
    </Description>
  </Container>
)

/**
 */
export const FifteenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>360 degree VR image output</Title>
    <Description>
      Arch-LOG Cloud Rendering also supports VR. Since you can easily output
      images for 360 degree VR, VR Presentations using headsets are also
      possible. Rendering with Arch-LOG Cloud Rendering By experiencing the
      image with a VR headset, it is possible to express that the planned
      building was completed on the spot.
    </Description>
  </Container>
)

/**
 */
export const SixteenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>
      Presentation high resolution
      <br />
      Rendering service (occupancy reservation system)
    </Title>
    <Description>
      If you want to render beyond 1920 x 1080 resolution, you can use a
      specially prepared cloud high-performance rendering for a fee. An
      exclusive reservation system that can use resources is available. By
      making a reservation for cloud resources in advance When using Arch-LOG
      Cloud Rendering, it is now possible to automatically occupy and use the
      occupied cloud resources. Become. * Click
      <Links.OnDemand>here</Links.OnDemand>
      for the exclusive reservation system
    </Description>
  </Container>
)

/**
 */
export const SeventeenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Private BIM library</Title>
    <Description>
      Are you having trouble managing your BIM library in-house? Arch-LOG has
      its own library that is not open to the public You can also register with
      Arch-LOG (* paid service). Own library with the same UI and workflow as
      Arch-LOG It is possible to centrally manage.
    </Description>
  </Container>
)

/**
 */
export const EighteenSection = ({
  components: {
    Container = Styled.Container,
    Title = Styled.Title,
    Description = Styled.Description,
  } = {},
}) => (
  <Container>
    <Title>Enterprise domain management capabilities</Title>
    <Description>
      If your company wants to manage the creation / invalidation of user
      accounts, you can manage accounts in the same domain. Domain management
      function can be provided. Domain administrator adds / disables
      administrator privileges for accounts in the same domain, You can create /
      invalidate a normal account, output a list, etc.
    </Description>
  </Container>
)
