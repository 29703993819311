import React from 'react'

import * as TextJa from './Text.ja'
import * as TextEn from './Text.en'

import { withLanguage } from '@arch-log/webapp.modules/i18n/components/withLanguage'

export const FirstSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.FirstSection {...props} />
    default:
      return <TextJa.FirstSection {...props} />
  }
})

export const SecondSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.SecondSection {...props} />
    default:
      return <TextJa.SecondSection {...props} />
  }
})

export const ThirdSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.ThirdSection {...props} />
    default:
      return <TextJa.ThirdSection {...props} />
  }
})

export const FourthSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.FourthSection {...props} />
    default:
      return <TextJa.FourthSection {...props} />
  }
})

export const FifthSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.FifthSection {...props} />
    default:
      return <TextJa.FifthSection {...props} />
  }
})

export const SixSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.SixSection {...props} />
    default:
      return <TextJa.SixSection {...props} />
  }
})

export const SevenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.SevenSection {...props} />
    default:
      return <TextJa.SevenSection {...props} />
  }
})

export const EightSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.EightSection {...props} />
    default:
      return <TextJa.EightSection {...props} />
  }
})

export const NineSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.NineSection {...props} />
    default:
      return <TextJa.NineSection {...props} />
  }
})

export const TenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.TenSection {...props} />
    default:
      return <TextJa.TenSection {...props} />
  }
})

export const ElevenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.ElevenSection {...props} />
    default:
      return <TextJa.ElevenSection {...props} />
  }
})

export const TwelveSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.TwelveSection {...props} />
    default:
      return <TextJa.TwelveSection {...props} />
  }
})

export const ThirteenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.ThirteenSection {...props} />
    default:
      return <TextJa.ThirteenSection {...props} />
  }
})

export const FourteenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.FourteenSection {...props} />
    default:
      return <TextJa.FourteenSection {...props} />
  }
})

export const FifteenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.FifteenSection {...props} />
    default:
      return <TextJa.FifteenSection {...props} />
  }
})

export const SixteenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.SixteenSection {...props} />
    default:
      return <TextJa.SixteenSection {...props} />
  }
})

export const SeventeenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.SeventeenSection {...props} />
    default:
      return <TextJa.SeventeenSection {...props} />
  }
})

export const EighteenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <TextEn.EighteenSection {...props} />
    default:
      return <TextJa.EighteenSection {...props} />
  }
})
