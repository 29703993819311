import React from 'react'

import * as Titles from '@arch-log/webapp.shared/Title'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
const Title = () => {
  return (
    <Titles.StaticSectionTitle>
      <Literals.Title />
    </Titles.StaticSectionTitle>
  )
}

/**
 */
const Description = () => {
  return (
    <div className={styles.Description}>
      <Literals.Description />
    </div>
  )
}

/**
 */
export const Default = ({ children }) => (
  <Container>
    <Title />
    <Description />
  </Container>
)
