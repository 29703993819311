import React from 'react'

import * as Links from '@arch-log/webapp.shared/Links'
import * as styles from './styles.module.scss'

/**
 */
export const ContactUs = ({ children, alt = 'ContactUs', ...props }) => (
  <Links.ToContact className={styles.Link} alt={alt} {...props}>
    {children}
  </Links.ToContact>
)

/**
 */
export const OnDemand = ({ children, alt = 'onDemand', ...props }) => (
  <Links.Static.ToOnDemand className={styles.Link} alt={alt} {...props}>
    {children}
  </Links.Static.ToOnDemand>
)
