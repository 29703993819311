import React from 'react'

import * as Layout from './Layout'
import * as Texts from './Texts'
import * as Images from './Images'

/**
 */
export const FirstSection = () => (
  <Layout.SectionContainer>
    <Texts.FirstSection />
    <Images.FirstSection />
  </Layout.SectionContainer>
)

/**
 */
export const SecondSection = () => (
  <Layout.SectionContainer>
    <Texts.SecondSection />
    <Images.SecondSection />
  </Layout.SectionContainer>
)

/**
 */
export const ThirdSection = () => (
  <Layout.SectionContainer>
    <Texts.ThirdSection />
    <Images.ThirdSection />
  </Layout.SectionContainer>
)

/**
 */
export const FourthSection = () => (
  <Layout.SectionContainer>
    <Texts.FourthSection />
    <Images.FourthSection />
  </Layout.SectionContainer>
)

/**
 */
export const FifthSection = () => (
  <Layout.SectionContainer>
    <Texts.FifthSection />
    <Images.FifthSection />
  </Layout.SectionContainer>
)

/**
 */
export const SixSection = () => (
  <Layout.SectionContainer>
    <Texts.SixSection />
    <Images.SixSection />
  </Layout.SectionContainer>
)

/**
 */
export const SevenSection = () => (
  <Layout.SectionContainer>
    <Texts.SevenSection />
    <Images.SevenSection />
  </Layout.SectionContainer>
)

/**
 */
export const EightSection = () => (
  <Layout.SectionContainer>
    <Texts.EightSection />
    <Images.EightSection />
  </Layout.SectionContainer>
)

/**
 */
export const NineSection = () => (
  <Layout.SectionContainer>
    <Texts.NineSection />
    <Images.NineSection />
  </Layout.SectionContainer>
)

/**
 */
export const TenSection = () => (
  <Layout.SectionContainer>
    <Texts.TenSection />
    <Images.TenSection />
  </Layout.SectionContainer>
)

/**
 */
export const ElevenSection = () => (
  <Layout.SectionContainer>
    <Texts.ElevenSection />
    <Images.ElevenSection />
  </Layout.SectionContainer>
)

/**
 */
export const TwelveSection = () => (
  <Layout.SectionContainer>
    <Texts.TwelveSection />
    <Images.TwelveSection />
  </Layout.SectionContainer>
)

/**
 */
export const ThirteenSection = () => (
  <Layout.SectionContainer>
    <Texts.ThirteenSection />
    <Images.ThirteenSection />
  </Layout.SectionContainer>
)

/**
 */
export const FourteenSection = () => (
  <Layout.SectionContainer>
    <Texts.FourteenSection />
    <Images.FourteenSection />
  </Layout.SectionContainer>
)

/**
 */
export const FifteenSection = () => (
  <Layout.SectionContainer>
    <Texts.FifteenSection />
    <Images.FifteenSection />
  </Layout.SectionContainer>
)

/**
 */
export const SixteenSection = () => (
  <Layout.SectionContainer>
    <Texts.SixteenSection />
    <Images.SixteenSection />
  </Layout.SectionContainer>
)

/**
 */
export const SeventeenSection = () => (
  <Layout.SectionContainer>
    <Texts.SeventeenSection />
    <Images.SeventeenSection />
  </Layout.SectionContainer>
)

/**
 */
export const EighteenSection = () => (
  <Layout.SectionContainer>
    <Texts.EighteenSection />
    <Images.EighteenSection />
  </Layout.SectionContainer>
)
