import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

/**
 */
export const FirstSection = ({ className }) => (
  <StaticImage
    src="../../images/merit/merit_1_1.jpg"
    alt="Merit Image"
    quality={100}
    className={className}
  />
)

/**
 */
export const SecondSection = ({ className }) => (
  <StaticImage
    src="../../images/merit/merit_1_2.jpg"
    alt="Merit Image"
    quality={100}
    className={className}
  />
)

/**
 */
export const ThirdSection = ({ className }) => (
  <StaticImage
    src="../../images/merit/merit_1_3.jpg"
    alt="Merit Image"
    quality={100}
    className={className}
  />
)

/**
 */
export const FourthSection = ({ className }) => (
  <StaticImage
    src="../../images/merit/merit_1_4.jpg"
    alt="Merit Image"
    quality={100}
    className={className}
  />
)

/**
 */
export const FifthSection = ({ className }) => (
  <StaticImage
    src="../../images/merit/merit_1_5.jpg"
    alt="Merit Image"
    quality={100}
    className={className}
  />
)

/**
 */
export const SixSection = ({ className }) => (
  <StaticImage
    src="../../images/merit/merit_1_6.jpg"
    alt="Merit Image"
    quality={100}
    className={className}
  />
)
