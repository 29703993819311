import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('app')('MeritPage.FunctionSection.Title')

/**
 */
export const Description = translated('app')(
  'MeritPage.FunctionSection.Description',
)
