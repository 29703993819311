import React from 'react'

import { Container, Title, Description, Annotation } from './Layout'

/**
 */
export const FirstSection = () => (
  <Container>
    <Title>Directly search and compare building materials.</Title>
    <Description>
      Arch-LOG is highly efficient when it comes to searching for materials. It
      is easier than ever to search for building materials across a wide range
      of manufacturers, all from one location. You will no longer need to jump
      between multiple manufacturer sites to compare.
    </Description>
  </Container>
)

/**
 */
export const SecondSection = () => (
  <Container>
    <Title>Free to use and keeps evolving.</Title>
    <Description>
      Arch-LOG is free to use! So, it's a loss if you don't use it anyway. From
      now on New functions * will be added one after another.
    </Description>
    <Annotation>
      * Finish schedule creation function, database function, animation
      rendering, etc.
    </Annotation>
  </Container>
)

/**
 */
export const ThirdSection = () => (
  <Container>
    <Title>Project-based building material management and FM.</Title>
    <Description>
      Information is naturally gathered for each project. that? What was the
      building material used in that project? I didn't notice it was out of
      print ... I can't find the instructions ... That will not happen. Of
      course, the data can be used not only during project planning, design, and
      construction, but also as FM.
    </Description>
  </Container>
)

/**
 */
export const FourthSection = () => (
  <Container>
    <Title>Business efficiency ・ DX is all Arch-LOG.</Title>
    <Description>
      Not only building material search, but also batch sample request function,
      material board tag management function, etc. There are a lot of functions
      that lead to operational efficiency. For DX in the construction industry,
      leave it to Arch-LOG.
    </Description>
  </Container>
)

/**
 */
export const FifthSection = () => (
  <Container>
    <Title>You can share it with everyone.</Title>
    <Description>
      Since project information, material boards, etc. can be shared by
      authority on the cloud, Teamwork is possible together even in remote
      locations. Because it can be completed online Project management that is
      not affected by social conditions is possible.
    </Description>
  </Container>
)

/**
 */
export const SixSection = () => (
  <Container>
    <Title>BIM correspondence.</Title>
    <Description>
      With Arch-LOG, you're ready to go with BIM. With BIM software You can
      connect with a plug-in and directly read BIM data that includes building
      material data. Of BIM data The information is always up-to-date at the
      time of download, so the data is always fresh.
    </Description>
  </Container>
)
