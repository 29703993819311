import React from 'react'

import { Container, Title, Description, Annotation } from './Layout'

/**
 */
export const FirstSection = () => (
  <Container>
    <Title>建材を、直接検索・比較。</Title>
    <Description>
      あらゆる建材メーカの建材を直接検索可能なので、建材を探すならArchーLOGが圧倒的に効率的。それぞれのメーカーのサイトに飛んで、探すより圧倒的に楽です。簡単に同一のユーザーインターフェースで他のメーカーとの比較も簡単です。
    </Description>
  </Container>
)

/**
 */
export const SecondSection = () => (
  <Container>
    <Title>無料で使え、進化し続ける。</Title>
    <Description>
      Arch-LOGは使用料無料!なので、とにかく使って見ないと損です。これからも続々と新機能※が追加されます。
    </Description>
    <Annotation>
      ※仕上表作成機能、データベース機能、アニメーションレンダリング等
    </Annotation>
  </Container>
)

/**
 */
export const ThirdSection = () => (
  <Container>
    <Title>プロジェクトベースの、建材管理とFM。</Title>
    <Description>
      プロジェクトごとに自然と情報がまとまる。あれ？あのプロジェクトで使った建材何だっけ？廃番に気づかなかった...。説明書が見つからない...。そんな事がなくなります。当然、プロジェクト企画・設計・施工時だけではなく、FMとしてもデータを利用可能です。
    </Description>
  </Container>
)

/**
 */
export const FourthSection = () => (
  <Container>
    <Title>業務の効率化・DXは、すべてArch-LOGで。</Title>
    <Description>
      建材検索はもちろんですが、一括サンプル請求機能、マテリアルボードタグ管理機能など、業務効率化に繋がる機能が目白押しです。建築業界のDXはArch-LOGにお任せください。
    </Description>
  </Container>
)

/**
 */
export const FifthSection = () => (
  <Container>
    <Title>皆で、共有できる。</Title>
    <Description>
      プロジェクト情報、マテリアルボードなどがクラウド上で権限別に共有可能なので、離れている場所でも一緒にチームワークが可能です。オンラインで完結できるので、社会情勢に左右されないプロジェクトマネージメントが可能です。
    </Description>
  </Container>
)

/**
 */
export const SixSection = () => (
  <Container>
    <Title>BIM対応</Title>
    <Description>
      Arch-LOGを使えば、それだけでBIMに対応する準備も完璧です。BIMソフトウェアとプラグインで繋がり、直接建材データを内包したBIMデータを読み込めます。BIMデータの情報はダウンロード時に常に最新のものが取得されるので、常にデータが新鮮です。
    </Description>
  </Container>
)
