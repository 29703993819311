import React from 'react'

import * as styles from './styles.module.scss'
import * as Sections from './Sections'

import { Default as SectionHeadline } from './SectionHeadline'

const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

const Header = () => (
  <div className={styles.Header}>
    <SectionHeadline />
  </div>
)

const Body = () => (
  <div className={styles.Body}>
    <Sections.FirstSection />
    <Sections.SecondSection />
    <Sections.ThirdSection />
    <Sections.FourthSection />
    <Sections.FifthSection />
    <Sections.SixSection />
  </div>
)

export const Content = () => (
  <Container>
    <Header />
    <Body />
  </Container>
)
