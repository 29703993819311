import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('app')('MeritPage.MeritSection.Title')

/**
 */
export const Description = translated('app')(
  'MeritPage.MeritSection.Description',
)
