import React from 'react'

import FirstSectionImg from 'images/merit/merit_2_1.jpg'
import SecondSectionImg from 'images/merit/merit_2_2.jpg'
import ThirdSectionImg from 'images/merit/merit_2_3.jpg'
import FourthSectionImg from 'images/merit/merit_2_4.jpg'
import FifthSectionImg from 'images/merit/merit_2_5.jpg'
import SixSectionImg from 'images/merit/merit_2_6.jpg'
import SevenSectionImg from 'images/merit/merit_2_7.jpg'
import EightSectionImg from 'images/merit/merit_2_8.jpg'
import NineSectionImg from 'images/merit/merit_2_9.jpg'
import TenSectionImg from 'images/merit/merit_2_10.jpg'
import ElevenSectionImg from 'images/merit/merit_2_11.jpg'
import TwelveSectionImg from 'images/merit/merit_2_12.jpg'
import ThirteenSectionImg from 'images/merit/merit_2_13.jpg'
import FourteenSectionImg from 'images/merit/merit_2_14.jpg'
import FifteenSectionImg from 'images/merit/merit_2_15.jpg'
import SixteenSectionImg from 'images/merit/merit_2_16.jpg'
import SeventeenSectionImg from 'images/merit/merit_2_17.jpg'
import EighteenSectionImg from 'images/merit/merit_2_18.jpg'

import * as styles from './styles.module.scss'

/**
 */
export const FirstSection = () => {
  return (
    <img
      className={styles.Image}
      src={FirstSectionImg}
      alt="project management"
    />
  )
}

/**
 */
export const SecondSection = () => {
  return (
    <img
      className={styles.Image}
      src={SecondSectionImg}
      alt="Notification function"
    />
  )
}

/**
 */
export const ThirdSection = () => {
  return (
    <img
      className={styles.Image}
      src={ThirdSectionImg}
      alt="Sample Document request"
    />
  )
}

/**
 */
export const FourthSection = () => {
  return (
    <img
      className={styles.Image}
      src={FourthSectionImg}
      alt="Material boardn"
    />
  )
}

/**
 */
export const FifthSection = () => {
  return (
    <img className={styles.Image} src={FifthSectionImg} alt="collaboration" />
  )
}

/**
 */
export const SixSection = () => {
  return <img className={styles.Image} src={SixSectionImg} alt="BIM download" />
}

/**
 */
export const SevenSection = () => {
  return (
    <img className={styles.Image} src={SevenSectionImg} alt="BIM request" />
  )
}

/**
 */
export const EightSection = () => {
  return (
    <img
      className={styles.Image}
      src={EightSectionImg}
      alt="Shared parameters for attracting customers"
    />
  )
}

/**
 */
export const NineSection = () => {
  return (
    <img
      className={styles.Image}
      src={NineSectionImg}
      alt="Photo real-time rendering"
    />
  )
}

/**
 */
export const TenSection = () => {
  return (
    <img
      className={styles.Image}
      src={TenSectionImg}
      alt="Lightweight BIM data"
    />
  )
}

/**
 */
export const ElevenSection = () => {
  return (
    <img
      className={styles.Image}
      src={ElevenSectionImg}
      alt="Drawing representation corresponding to LOD"
    />
  )
}

/**
 */
export const TwelveSection = () => {
  return (
    <img
      className={styles.Image}
      src={TwelveSectionImg}
      alt="Anyone CG expert"
    />
  )
}

/**
 */
export const ThirteenSection = () => {
  return (
    <img
      className={styles.Image}
      src={ThirteenSectionImg}
      alt="Advanced rendering settings"
    />
  )
}

/**
 */
export const FourteenSection = () => {
  return (
    <img
      className={styles.Image}
      src={FourteenSectionImg}
      alt="Brightness illuminance simulation"
    />
  )
}
/**
 */
export const FifteenSection = () => {
  return (
    <img
      className={styles.Image}
      src={FifteenSectionImg}
      alt="360 degree VR image output"
    />
  )
}

/**
 */
export const SixteenSection = () => {
  return (
    <img
      className={styles.Image}
      src={SixteenSectionImg}
      alt="Presentation high resolution
  Rendering service "
    />
  )
}

/**
 */
export const SeventeenSection = () => {
  return (
    <img
      className={styles.Image}
      src={SeventeenSectionImg}
      alt="Private BIM library"
    />
  )
}

/**
 */
export const EighteenSection = () => {
  return (
    <img
      className={styles.Image}
      src={EighteenSectionImg}
      alt="Enterprise domain management capabilities"
    />
  )
}
