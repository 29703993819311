import React from 'react'

import * as Layout from './Layout'
import * as Texts from './Texts'
import * as Images from './Images'

/**
 */
export const FirstSection = () => (
  <Layout.SectionContainer>
    <Images.FirstSection />
    <Texts.FirstSection />
  </Layout.SectionContainer>
)

/**
 */
export const SecondSection = () => (
  <Layout.SectionContainer>
    <Images.SecondSection />
    <Texts.SecondSection />
  </Layout.SectionContainer>
)

/**
 */
export const ThirdSection = () => (
  <Layout.SectionContainer>
    <Images.ThirdSection />
    <Texts.ThirdSection />
  </Layout.SectionContainer>
)

/**
 */
export const FourthSection = () => (
  <Layout.SectionContainer>
    <Images.FourthSection />
    <Texts.FourthSection />
  </Layout.SectionContainer>
)

/**
 */
export const FifthSection = () => (
  <Layout.SectionContainer>
    <Images.FifthSection />
    <Texts.FifthSection />
  </Layout.SectionContainer>
)

/**
 */
export const SixSection = () => (
  <Layout.SectionContainer>
    <Images.SixSection />
    <Texts.SixSection />
  </Layout.SectionContainer>
)
